



import { Vue, Component, Mixins } from 'vue-property-decorator'

import * as THREE from 'three'

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'

import { CSG } from 'three-csg-ts'

const OrbitControls = require('three-orbit-controls')(THREE)

@Component
export default class extends Mixins() {
  renderer: any

  scene: any

  camera: any

  width: number = 0
  height: number = 0

  ref1: any

  floor: any
  floor1: any

  materialArrayA: any = []
  materialArrayB: any = []
  matArrayA: any = []
  matArrayB: any = []
  matArrayC: any = []

  dummy: any = new THREE.Object3D()

  created() {}

  mounted() {
    this.ref1 = this.$refs.room
    setTimeout(() => {
      this.width = Number(
        // @ts-ignore
        window.getComputedStyle(this.ref1).width.replace('px', '')
      )
      this.height = Number(
        // @ts-ignore
        window.getComputedStyle(this.ref1).height.replace('px', '')
      )
      this.initScene()
      this.initCamera()
      this.initRender()
      this.initEvent()
      this.initControls()
      this.initLight()

      this.createWallMaterial()

      this.createFloor()

      this.createLayout()

      this.animate()
    }, 1000)
  }

  initScene() {
    this.scene = new THREE.Scene()
    const axesHelper = new THREE.AxesHelper(4500)
    this.scene.add(axesHelper)
  }

  initCamera() {
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      0.1,
      10000
    )

    // this.scene.add(this.camera)
  }

  initRender() {
    this.renderer = new THREE.WebGLRenderer()
    this.renderer.setSize(this.width, this.height)
    // document.body.appendChild(renderer.domElement)

    this.ref1.append(this.renderer.domElement)

    this.renderer.setClearColor(0x4682b4, 1.0)
  }

  initEvent() {}

  initControls() {
    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.camera.position.set(0, 4000, 1800)
    this.camera.lookAt(this.scene.position)
    this.camera.lookAt(0, 0, 0)
    controls.update()
  }

  initLight() {
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    directionalLight.position.set(0, 100, 0).normalize()
    this.scene.add(directionalLight)

    const ambient = new THREE.AmbientLight(0xffffff, 1)
    ambient.position.set(0, 0, 0).normalize()
    this.scene.add(ambient)
  }

  createWallMaterial() {
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //前  0xafc0ca :灰色
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //后
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //上  0xd6e4ec： 偏白色
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //下
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //左    0xafc0ca :灰色
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //右

    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //前  0xafc0ca :灰色
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //后  0x9cb2d1：淡紫
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //上  0xd6e4ec： 偏白色
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //下
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //左   0xafc0ca :灰色
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0xafc0ca })) //右

    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //前  0xafc0ca :灰色
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //后  0x9cb2d1：淡紫
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //上  0xd6e4ec： 偏白色
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //下
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //左   0xafc0ca :灰色
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //右
  }

  createFloor() {
    // console.log(publicPath)
  }

  animate() {
    requestAnimationFrame(this.animate)

    this.renderer.render(this.scene, this.camera)
  }

  createCubeWall(
    w: number,
    h: number,
    d: number,
    angle: any,
    material: any,
    x: number,
    y: number,
    z: number
  ) {
    const geometry = new THREE.BoxGeometry(w, h, d)
    const cube = new THREE.Mesh(geometry, material)

    cube.position.x = x
    cube.position.y = y
    cube.position.z = z

    cube.rotation.y += angle * Math.PI

    // this.scene.add(cube)

    return cube
  }

  createGround(
    w: number = 1500,
    h: number = 710,
    d: number = 1,
    x: number,
    y: number,
    z: number
  ) {}

  createLayout() {
    // 上北，下南，左西，右东
    const wall = this.createCubeWall(
      5960,
      1000,
      10,
      0,
      new THREE.MeshNormalMaterial(),
      0,
      500,
      1350
    )

    // 门
    const doorGeometry = new THREE.BoxGeometry(170, 240, 25)
    const doorMaterial = new THREE.MeshNormalMaterial()
    doorMaterial.opacity = 1.0
    doorMaterial.transparent = true

    const door = new THREE.Mesh(doorGeometry, doorMaterial)

    door.position.y = 120
    door.position.z = 1350
    door.position.x = 2500

    // const wallBSP = new ThreeBSP(wall)
    // const doorBSP = new ThreeBSP(door)
    wall.updateMatrix()
    door.updateMatrix()

    const result = CSG.subtract(wall, door)

    // result.updateMatrix()

    console.log(result)

    this.scene.add(result)

    // 门上边的标题
    const loader = new FontLoader()

    loader.load('/Alibaba PuHuiTi_Regular.json', (font: any) => {
      const geometry = new TextGeometry('雄峰AI羽毛球馆', {
        font: font,
        size: 80,
        height: 10,
        curveSegments: 12,
        bevelEnabled: false,
        bevelThickness: 20,
        bevelSize: 8,
        bevelSegments: 5,
      })

      const textMesh1 = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial())

      textMesh1.position.y = 400
      textMesh1.position.z = 1370
      textMesh1.position.x = 2150
      this.scene.add(textMesh1)
    })
  }
}
